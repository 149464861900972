<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ConfirmContest :data-dic="dataDic" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ConfirmContest from '@/components/company/contest/ConfirmContest.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    ConfirmContest,
  },
  data() {
    return {
      dataDic: {},
    }
  },
  computed: {
    ...mapGetters('jobApplicantContest', ['contestList']),
  },
  mounted() {
    if (this.contestList !== 'undefined') {
      const index = this.contestList.findIndex(v => v.docID === this.$route.query.id)
      this.dataDic = this.contestList[index]
    }
  },
}
</script>

<style lang="scss">
</style>
